import { Controller } from '@hotwired/stimulus'
import { TableBuilderView } from "../../views/views.js"
import { TableElementTemplate, ExpandedTableElementTemplate, PopoverTemplate } from "../../templates/online_job_plan/templates.js"
import { get } from '@rails/request.js'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['timetable', 'searchInput', 'searchClear', 'sortMethodOption', 'expander']
  static values = { url: String,
                    editJobContentPathTemplate: String,
                    sortablePathTemplate: String,
                    searchQuery: String,
                    sortMethod: { type: String, default: 'chronological_order' },
                    expanded: { type: Boolean, default: false } }

  initialize() {
    this.setInitialExpanded()
  }

  connect() {
    let sortMethodRadio = _.find(this.sortMethodOptionTargets, (t) => t.value == this.sortMethodValue)
    if(sortMethodRadio) {
      sortMethodRadio.checked = true
    }

    this.fetchAndDisplayTable()
  }

  async fetchAndDisplayTable() {
    const response = await get(this.urlValue, {
      headers: {
        contentType: 'application/json'
      }
    })

    if(response.ok) {
      this.tableData = await response.json
      const tableBuilderOptions = { sortablePathTemplate: this.sortablePathTemplateValue, sort_method: this.sortMethodValue }

      this.table_builder = new TableBuilderView(this.tableData, tableBuilderOptions)
      this.timetableTarget.innerHTML = this.table_builder.render()
      this.setContent()
    } else {
      this.timetableTarget.innerHTML(err)
    }
  }

  reRender() {
    this.table_builder.tables = this.table_builder.defaultTables()
    this.table_builder.buildTables(this.sortMethodValue, this.searchQueryValue)
    this.timetableTarget.innerHTML = this.table_builder.render()
    this.setContent()
  }

  sortBy(event) {
    this.sortMethodValue = event.params.sortMethod

    // set sort method in the url
    if (typeof (history.replaceState) != 'undefined') {
      const newUrl = `${window.location.pathname}?sort_method=${this.sortMethodValue}${window.location.hash}`
      history.replaceState({}, null, newUrl)
    }

    this.reRender()
  }

  search(event) {
    this.searchQueryValue = event.target.value.toLowerCase()

    this.reRender()
  }

  searchReset() {
    this.searchInputTarget.value = ''
    this.searchQueryValue = ''

    this.reRender()
  }

  // https://stimulus.hotwired.dev/reference/values#change-callbacks
  searchQueryValueChanged(a, b) {
    if(this.hasSearchClearTarget) {
      // show/hide clear search input button
      if(this.searchQueryValue == '') {
        this.searchClearTarget.classList.add('d-none')
      } else {
        this.searchClearTarget.classList.remove('d-none')
      }
    }
  }

  addPopovers() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours_long = this.table_builder.template_data[element.id].hours_long
          const pas = this.table_builder.template_data[element.id].pas
          const impacted_hours = this.table_builder.template_data[element.id].impacted_hours
          const impacted_pas = this.table_builder.template_data[element.id].impacted_pas

          const html = PopoverTemplate({
            scheduled: job_content.scheduled,
            start_time: job_content.start_time,
            end_time: job_content.end_time,
            categorisation: job_content.categorisation,
            summary: job_content.summary,
            hours_long,
            pas,
            impacted_hours,
            impacted_pas
          })

          const selector = `#${element.id} .activity`
          let el = $(this.element).find(selector)
          let customClass = job_content.categorisation.toLowerCase()

          let options = {
            container: this.element,
            content: html,
            html: true,
            placement: 'auto',
            trigger: 'hover',
            // trigger: 'click', // Used for styling
            customClass: customClass + ' timetable-week-overview'
          }

          new bootstrap.Popover(el, options)
        }, this)
      }, this)
    }, this)
  }

  setInitialExpanded() {
    // read expanded status from cookie and set initial checkbox value
    if(this.hasExpanderTarget) {
      this.expandedValue = Cookies.get('timetableExpanded') || false
      this.expanderTarget.checked = this.expandedValue
    }
  }

  toggleExpand() {
    this.expandedValue = !this.expandedValue
    this.setContent()
    Cookies.set('timetableExpanded', this.expandedValue)
  }

  setContent() {
    if(this.expandedValue) {
      this.setExpandedContent()
    } else {
      this.setNormalContent()
    }
  }

  setNormalContent() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours = this.table_builder.template_data[element.id].hours
          const pas = this.table_builder.template_data[element.id].pas
          const overlaps_midnight_class = this.table_builder.template_data[element.id].overlaps_midnight_class
          const impacted_hours = this.table_builder.template_data[element.id].impacted_hours
          const impacted_pas = this.table_builder.template_data[element.id].impacted_pas

          const html = TableElementTemplate({
            edit_job_content_path: this.prepareEditJobContentPath(job_content.id),
            categorisation: job_content.categorisation.toLowerCase(),
            one_line_summary: job_content.one_line_summary,
            hours,
            pas,
            impacted_hours,
            impacted_pas,
            overlaps_midnight_class,
          })
          $(this.element).find('#' + element.id).html(html)
        }, this)
      }, this)
    }, this)
    this.addPopovers()
  }

  setExpandedContent() {
    _.each(this.table_builder.tables, function(table) {
      _.each(table.rows, function(row) {
        _.each(_.filter(row, 'id'), function(element) {
          const job_content = this.table_builder.template_data[element.id].job_content
          const hours_long = this.table_builder.template_data[element.id].hours_long
          const pas = this.table_builder.template_data[element.id].pas
          const overlaps_midnight_class = this.table_builder.template_data[element.id].overlaps_midnight_class
          const impacted_hours = this.table_builder.template_data[element.id].impacted_hours
          const impacted_pas = this.table_builder.template_data[element.id].impacted_pas

          const html = ExpandedTableElementTemplate({
            edit_job_content_path: this.prepareEditJobContentPath(job_content.id),
            scheduled: job_content.scheduled,
            start_time: job_content.start_time,
            end_time: job_content.end_time,
            categorisation: job_content.categorisation,
            summary: job_content.summary,
            hours_long,
            pas,
            impacted_hours,
            impacted_pas,
            overlaps_midnight_class
          })

          $(this.element).find('#' + element.id).html(html)
        }, this)
      }, this)
    }, this)
  }

  prepareEditJobContentPath(job_content_id) {
    return this.editJobContentPathTemplateValue.replace('JOB_CONTENT_ID', job_content_id)
  }
}
