// used for custom file upload - app/views/shared/file_upload

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileName', 'fileInput']
  static values = { multiple: { type: Boolean, default: false } }

  fileSelected(e) {
    const input = e.currentTarget
    const files = input.files

    const text = this.prepareFileSelectedText(files.length)

    if(this.hasFileNameTarget) {
      this.fileNameTarget.setAttribute('value', text)
    } else {
      alert(text)
    }

    //if(this.multipleValue) {
      // communicate with parent controller edocs--form
      const fileNames = _.pluck(files, 'name')
      this.dispatch('files-selected', { detail: { fileNames: fileNames } })
    //}
  }

  reset() {
    this.fileNameTarget.setAttribute('value', '')

    //if(this.multipleValue) {
      // communicate with parent controller edocs--form
      this.dispatch('files-reset')
    //}
  }

  // uses clicked trash icon next to edoc title input
  fileRemoved(e) {
    //console.log('fileRemoved | e', e)
    const count = e.detail.new_edocs_count

    if(count == 0) {
      this.reset()
    } else {
      const first_name = this.fileInputTarget.files[0].name
      const text = this.prepareFileSelectedText(count, first_name)
      this.fileNameTarget.setAttribute('value', text)
    }
  }

  prepareFileSelectedText(count) {
    return count == 1 ? this.fileInputTarget.files[0].name : `${count} files selected`
  }
}
