import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitEnd(event) {
    if(event.detail.success) {
      // on successful form submit set data attribute to reload page after modal close
      const modalEl = this.element.closest('.modal')
      modalEl.setAttribute('data-reload', true)
    }
  }
}
