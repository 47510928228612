// TODO not used, remove when all functionalities imported to stimulus controller

import { FetchRequest } from '@rails/request.js'
import modalLoad from 'shared/modal_load'
import modalOpen from 'shared/modal_open'

Ready(function() {
  $('body').on('click', '[data-modal]', (e) => {
    e.preventDefault()

    const location = e.currentTarget.getAttribute('href') || e.currentTarget.getAttribute('data-url')
    modalLoad(location)
  })

  $(document).on('modal:open', modalOpen)
})
