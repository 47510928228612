// used in non-medical job plans, job content form

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect(){
    // get value of 'repeats_weekly_each_days_of_the_week' select (Mon-Sun) and use to highlight selected days
    var days_of_the_week= $("#job_content_repeats_monthly_on_days_of_the_week").val();
    this.selectDaysOfTheWeek(days_of_the_week);

    this.pluralizeMonth($("#job_content_repeats_every_n_months").val());
  }

  // when click on day of the week
  onClickDay(event){
    // add selected class
    $(event.target).toggleClass('selected');

    // and update hidden array field
    var selected_days=[];
    $.each($(event.target).closest('tr').children("td.selected"), function( index, value ) {
      selected_days.push(value.getAttribute('data-value'))
    });
    $("#job_content_repeats_monthly_on_days_of_the_week").val(selected_days);
  }

  onSelectEveryMonthsOption(event){
    this.pluralizeMonth(event.target.value);
  }

  //

  selectDaysOfTheWeek(days_of_the_week) {
    if (days_of_the_week) {
      $.each(days_of_the_week, function( index, value ) {
        $("td[data-value='" + value +"']").addClass('selected');
      });
    }
  }

  pluralizeMonth(count){
    if (count > 1){
      $('.months').text('months');
    } else {
      $('.months').text('month');
    }
  }

}
