// TODO not used, remove when all functionalities imported to stimulus controller

const modalOpen = (data) => {
  $('#modal-tabs a').click((e) => {
    e.preventDefault()
    $(this).tab('show')
  })
}

export default modalOpen
