// toggle between repeats weekly, monthly and never
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'repeatsSelect' ]

  connect(){
    this.toggleRepeats(this.repeatsSelectTarget.value);
  }

  onSelectRepeatsOption(event) {
    this.toggleRepeats(event.target.value)
  }

  toggleRepeats(repeats){
    if(repeats == 'weekly'){
      $('#repeats-monthly').hide();
      $('#repeats-weekly').show();
      $('.repeats-every').show();
    }
    else if(repeats == 'monthly'){
      $('#repeats-monthly').show();
      $('#repeats-weekly').hide();
      $('.repeats-every').show();
    }
    else if(repeats == 'never'){
      $('.repeats-every').hide();
    }
  }
}
