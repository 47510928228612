import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'newRagStatus', 'flashContainer' ]
  static values = { appraisalDatasetId: String }

  updateRagStatus(event) {
    event.preventDefault()

    this.newRagStatusTarget.value = event.params.ragStatus

    // Rails.fire(this.element, 'submit') rails-ujs one
    this.element.requestSubmit() // HTMLFormElement one
    // both work
  }

  // send event about justification rag status update, which will be catched by rag-controller
  onSubmitEnd(event) {
    if(event.detail.success) {
      const detail = {
        justification: {
          rag_status: this.newRagStatusTarget.value,
          appraisal_dataset_id: this.appraisalDatasetIdValue
        }
      }
      this.dispatch('updated', { detail: detail })
    }
  }
}
