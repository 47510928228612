window.SARDUtils = {
  isPresent: function(object) {
    return object != undefined &&  object.toString().trim() !== ''
  },
  presense: function(object) {
    return this.isPresent(object) ? object : null
  },
  appendToQueryString: function(url, params = {}) {
    [base, query] = url.split('?')
    params = {
      ...this.parseQuery(query),
      ...params
    }
    query = this.buildQuery(params)
    return [base, query].filter(v => this.isPresent(v)).join('?')
  },
  parseQuery: function(query) {
    if (!this.isPresent(query)) {
      return {}
    }
    return query.split('&').reduce((h, pair) => {
      [k, v] = pair.split('=')
      h[k] = decodeURIComponent(v)
      return h
    }, {})
  },
  buildQuery: function(params) {
    return Object.entries(params).map(([k, v]) => {
      return [k, encodeURIComponent(v)].join('=')
    }).join('&')
  }
}
