// show/hide subject target when checkbox/radio checked
// hideOnCheck (Boolean): is set to 'true' for subject container makes it to hide on check checkbox/radio
// hideOthers (Boolean): hide other targets, useful when controller used with select

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['subject']
  static values = { hideOthers: Boolean }

  toggle(event) {
    const hideOthers = this._hideOthers(event)

    //console.log('visibility | toggle | event.params |', event.params)

    if(this._hasManySubjects()) {
      let isChecked = false
      const that = this

      // expected value passed in params or read from event element
      const switchMap = event.params.switchMap ? event.params.switchMap : event.target.value

      let targets = this.subjectTargets.filter(t => t.dataset.visibilitySwitchMap == switchMap)
      //console.log('visibility | targets', targets)
      _.each(targets, function(target) {
        //console.log("target.getAttribute('data-visibility-switch-show-value')", target.getAttribute('data-visibility-switch-show-value'))

        // switch by show value if set for target container
        let showValueDataAttr = target.getAttribute('data-visibility-switch-show-value')
        //console.log('visibility | showValueDataAttr', showValueDataAttr)
        if(!!showValueDataAttr) {
          // at the moment used in custom questions only
          isChecked = showValueDataAttr === event.target.value
        } else {
          isChecked = that._isChecked(event) // use generic method
          //console.log('visibility | isChecked', isChecked)

          if(target.dataset.visibilityHideOnCheck == 'true') {
            isChecked = !isChecked
          }
        }
        that._toggleSingle(target, isChecked, hideOthers)
      })
    } else {
      const isChecked = this._isChecked(event)
      if(this.subjectTarget.dataset.visibilityHideOnCheck == 'true') {
        isChecked = !isChecked
      }
      this._toggleSingle(this.subjectTarget, isChecked, hideOthers)
    }
  }

  _toggleSingle(subject, is_checked, hide_others = false) {
    // if(is_checked === null) {
    //   console.log('is checked null for subject', subject)
    // }

    if(is_checked) {
      subject.classList.remove('d-none')
    } else {
      subject.classList.add('d-none')
    }

    const that = this

    if(hide_others && this._hasManySubjects()) {
      let other_targets = this.subjectTargets.filter(t => t.dataset.visibilitySwitchMap != subject.dataset.visibilitySwitchMap)

      _.each(other_targets, function(t) {
        t.classList.add('d-none')
      })
    }
  }

  _isChecked(event) {
    let is_checked = false

    // console.log('visibility | isChecked | event.target', event.target)

    if(event.target.type == 'radio') {
      is_checked = event.target.value == 'true' || event.target.value == 'yes'
    } else if (event.target.type == 'checkbox') {
      is_checked = event.target.checked
    } else if (event.target.type == 'select-one') {
      is_checked = true
    } else {
      throw 'VisibilityController: unsupported toggle element type'
    }

    return is_checked
  }

  _hasManySubjects() {
    return this.subjectTargets.length > 1
  }

  // may be set on controller level as value
  // or passed via event params
  _hideOthers(event) {
    return (this.hideOthersValue || event.params.hideOthers)
  }
}
