import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['actionLink']

  tabChangedCallback(event) {
    if(event.detail.subjectSelected) {
      return
    }

    this.actionLinkTargets.forEach((target) => {
      target.classList.add('d-none')
    })
  }

  rangeChangedCallback(event) {
    const start = event.detail.start
    const end = event.detail.end

    this.actionLinkTargets.forEach((target) => {
      let url = new URL(target.href)

      url.searchParams.set('active_view', event.detail.view)

      if(start) {
        url.searchParams.set('start', start.toISOString().split('T')[0])
      } else {
        url.searchParams.delete('start')
      }

      if(end) {
        url.searchParams.set('end', end.toISOString().split('T')[0])
      } else {
        url.searchParams.delete('end')
      }

      if(event.detail.view != 'customYear') {
        url.searchParams.set('month', event.detail.monthStr)
      } else {
        url.searchParams.delete('month')
      }

      target.href = url.toString()
    })
  }
}
