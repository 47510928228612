import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['add_item', 'template', 'removeItem']
  static values = { key: String, allowRemoveLastItem: { type: Boolean, default: true } }

  add_association(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('afterbegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest('.nested-fields')
    item.querySelector("input[name*='_destroy']").value = 'true'
    //const attachmentIdsInput = item.querySelector("input[name*='attachment_id']")
    //attachmentIdsInput.remove()
    item.classList.add('d-none')
    this.updateRemoveItemVisibility()
  }

  removeItemTargetConnected() {
    this.updateRemoveItemVisibility()
  }

  updateRemoveItemVisibility() {
    if(this.allowRemoveLastItemValue) {
      return
    }

    const visibleNestedFields = this.element.querySelectorAll('.nested-fields:not(.d-none)')
    const length = visibleNestedFields.length

    if(length == 1) {
      this.removeItemTargets.forEach(target => target.classList.add('d-none'))
    } else {
      this.removeItemTargets.forEach(target => target.classList.remove('d-none'))
    }
  }
}
