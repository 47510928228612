// responsible for occurences overwrite logic in Summary fieldset

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'overrideWorkedSwitch', 'prospectiveCoverSwitch', 'overrideTotalSwitch',
                     'noOverride', 'overrideWorked', 'overrideTotal',
                     'workedOccurencesWas', 'totalOccurencesWas' ]

  toggleOverrideWorked(event) {
    const checked = event.target.checked

    this.prospectiveCoverSwitchTarget.checked = false
    this.overrideTotalSwitchTarget.checked = false

    this.overrideTotalTargets.forEach((t) => t.classList.add('d-none'))

    if(checked) {
      this.workedOccurencesWasTarget.classList.remove('d-none')
      this.totalOccurencesWasTarget.classList.remove('d-none')

      this.noOverrideTargets.forEach((t) => t.classList.add('d-none'))
      this.overrideWorkedTargets.forEach((t) => t.classList.remove('d-none'))
    } else {
      this.workedOccurencesWasTarget.classList.add('d-none')
      this.totalOccurencesWasTarget.classList.add('d-none')

      this.noOverrideTargets.forEach((t) => t.classList.remove('d-none'))
      this.overrideWorkedTargets.forEach((t) => t.classList.add('d-none'))
    }
  }

  toggleProspectiveCover(event) {
    this.overrideWorkedSwitchTarget.checked = false

    this.overrideWorkedTargets.forEach((t) => t.classList.add('d-none'))

    this.workedOccurencesWasTarget.classList.add('d-none')
    this.totalOccurencesWasTarget.classList.add('d-none')

    this.noOverrideTargets.forEach((t) => t.classList.remove('d-none'))
  }

  toggleOverrideTotal(event) {
    const checked = event.target.checked

    this.overrideWorkedSwitchTarget.checked = false
    this.overrideWorkedTargets.forEach((t) => t.classList.add('d-none'))

    if(checked) {
      this.workedOccurencesWasTarget.classList.remove('d-none')
      this.totalOccurencesWasTarget.classList.remove('d-none')

      this.noOverrideTargets.forEach((t) => t.classList.add('d-none'))
      this.overrideTotalTargets.forEach((t) => t.classList.remove('d-none'))
    } else {
      this.workedOccurencesWasTarget.classList.add('d-none')
      this.totalOccurencesWasTarget.classList.add('d-none')

      this.noOverrideTargets.forEach((t) => t.classList.remove('d-none'))
      this.overrideTotalTargets.forEach((t) => t.classList.add('d-none'))
    }
  }
}
