// TODO not used, remove when all functionalities imported to stimulus controller

import { FetchRequest } from '@rails/request.js'

const modalLoad = async (location) => {
  const modal_holder_selector = '#modal-holder'
  const modal_selector = '.modal'

  const request = new FetchRequest('get', location, { responseKind: 'html' } )
  const response = await request.perform()

  if (response.ok) {
    //const body = await response.text
    const modal_html = await response.html

    $('.modal-backdrop').remove() // TODO needed ?
    $(modal_holder_selector).html(modal_html).find(modal_selector).modal()

    $(modal_selector).on('shown.bs.modal', (e) => {
      //console.log('modal id', $(this).attr('id') )
      $(document).trigger('modal:open') //, { dom_id: $(this).attr('id') }) // TODO
    })

    $(modal_selector).on('hide.bs.modal', function () {
      //console.log('hide.bs.modal')

      if ($(this).data('reload-url')) {
        window.location.href = $(this).data('reload-url')
      } else if ($(this).data('reload')) {
        window.location.reload(true)
      }
    })
  }
}

export default modalLoad
