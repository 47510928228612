import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static values = { orderFieldSelector: String }

  end() {
    if(this.orderFieldSelectorValue) {
      let order_fields = this.element.querySelectorAll(this.orderFieldSelectorValue)

      let order = 0
      for(let i = 0; i < order_fields.length; i++)
      {
        order_fields[i].value = order
        order--
      }
    }
    //super.end()
  }
}
