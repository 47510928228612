import { ExpandedHeaderTemplate, SummaryTemplate } from "./templates.js"

export default function ExpandedTableElementTemplate(data) {
  return `
    <div class="session ${data.overlaps_midnight_class}">
      <a class="activity ${data.categorisation.toLowerCase()}"
        href="/non_medical/online_job_plans/${data.job_plan_id}/activities/${data.job_content_id}/edit"
        data-toggle="modal"
        data-target="#activity-modal">
        <div class="category header">
          ${data.categorisation}
        </div>
        ${ExpandedHeaderTemplate({
          scheduled: data.scheduled,
          start_time: data.start_time,
          end_time: data.end_time
        })}
        <div class="header">
          ${data.hours} hours
        </div>
        <div>
          ${SummaryTemplate({
            summary: data.summary
          })}
        </div>
      </a>
    </div>
  `
}