// responsible for features in job content On-Call Supplement section

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['onCallSupplement', 'frequency', 'category']

  onCallMatrix = {
    "High": { A: "8.0%", B: "3.0%", SAS: "6.0%" },
    "Medium": { A: "5.0%", B: "2.0%", SAS: "4.0%" },
    "Low": { A: "3.0%", B: "1.0%", SAS: "2.0%" }
  }

  // when changing Frequency or Agreed Category set respective value for On call supplement
  onChangeFrequencyOrCategory() {
    const frequency = this._getFrequency()
    const category = this._getCategory()

    if(frequency && category) {
      const val = this.onCallMatrix[frequency][category]
      this.onCallSupplementTarget.value = val
    } else {
      this.onCallSupplementTarget.value = ''
    }
  }

  _getFrequency() {
    const selected_radio = this.frequencyTargets.find(t => t.checked)
    return selected_radio ? selected_radio.dataset.frequency : null
  }

  _getCategory() {
    return this.categoryTarget.value
  }
}