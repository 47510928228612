/*
 * FormChanges(string FormID | DOMelement FormNode)
 * Returns an array of changed form elements.
 * An empty array indicates no changes have been made.
 * NULL indicates that the form does not exist.
 *
 * By Craig Buckler,		http://twitter.com/craigbuckler
 * of OptimalWorks.net		http://optimalworks.net/
 * for SitePoint.com		http://sitepoint.com/
 *
 * Refer to https://www.sitepoint.com/javascript-form-change-checker/
 *
 * This code can be used without restriction.
 */

const formChanges = (form) => {
	// get form
	if (typeof form == "string") form = document.getElementById(form)
	if (!form || !form.nodeName || form.nodeName.toLowerCase() != "form") return null
	// find changed elements
	let changed = [], n, c, def, o, ol, opt, data_def_val
	//console.log('length', form.elements.length)
	for (let e = 0, el = form.elements.length; e < el; e++) {
		n = form.elements[e]
		c = false
		switch (n.nodeName.toLowerCase()) {

			// select boxes
			case "select":
				def = 0
				for (o = 0, ol = n.options.length; o < ol; o++) {
					opt = n.options[o]
					c = c || (opt.selected != opt.defaultSelected)
					if (opt.defaultSelected) def = o
				}
				if (c && !n.multiple) c = (def != n.selectedIndex)
				break
			
			// input / textarea
			case "textarea":
			case "input":

				switch (n.type.toLowerCase()) {
					case "checkbox":
					case "radio":
						// checkbox / radio
						c = (n.checked != n.defaultChecked)
						break
					default:
						// standard values

						// used e.g. when input has been replaced by e.g. stimulus and defaultValue is lost
						data_def_val = n.getAttribute('data-default-value')
						//console.log(`standard | id: ${n.getAttribute('id')} n.value: ${n.value} | data_def_val: ${data_def_val}`)

						c = (n.value != n.defaultValue) || (data_def_val && n.value != data_def_val)
						break
				}
				break
		}
		if (c) changed.push(n)
	}
	return changed
}

export default formChanges
