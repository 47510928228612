// receive event from justification controller and change menu item and header rag icon and color respectively

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['nav', 'header']
  static values = { portfolioSectionWidth: Number }

  justificationUpdated({ detail: content }) {
    const justification = content.justification
    const newRagClass = `rag-${justification.rag_status}`

    // update nav item rag icon
    let ragBox= this.navTarget.querySelector(`[data-id='${justification.appraisal_dataset_id}']`)
    let oldRagClass = ''
    _.map(ragBox.classList, function(k) {
      if(k && k.startsWith('rag-')) {
        oldRagClass = k
        ragBox.classList.remove(k)
      }
    })
    ragBox.classList.add(newRagClass)

    // update header rag color
    let header = this.headerTarget
    _.map(header.classList, function(k) {
      if(k && k.startsWith('rag-')) {
        header.classList.remove(k)
      }
    })
    this.headerTarget.classList.add(newRagClass)

    // update progress bar - increase width by 5% for each justification
   this.updateProgressWidth(oldRagClass, newRagClass)
  }

  updateProgressWidth(oldRagClass, newRagClass) {
    // Select the progress target element
    const progressTarget = document.getElementById('portfolio-progress');

    // Get the current width of the progress target
    let currentWidth = parseFloat(progressTarget.style.width);

    // Check if the current width is a valid number, if not, set it to 0
    if (isNaN(currentWidth)) {
      currentWidth = 0;
    }

    // Determine if the RAG status changed to or from red
    const wasRed = oldRagClass === 'rag-red';
    const isRed = newRagClass === 'rag-red';

    // Adjust the width based on the RAG status change
    if (wasRed && !isRed) {
      // Increase the width by 15 if the RAG status changed from red to non-red
      currentWidth = Math.min(100, currentWidth + this.portfolioSectionWidthValue);
    } else if (!wasRed && isRed) {
      // Decrease the width by 15 if the RAG status changed to red
      currentWidth = Math.max(0, currentWidth - this.portfolioSectionWidthValue);
    }

    // Set the new width of the progress target
    progressTarget.style.width = `${currentWidth}%`;
  }
}
