import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { loading: { type: Boolean, default: false }, loadingPartitionTable: { type: Boolean, default: false } }
  static targets = ['form', 'loading', 'loadingPartitionTable', 'resultsWrapper', 'results', 'reportFrame', 'partitionTableCard']

  connect() {
    this.formTarget.addEventListener('submit', async (_event) => {
      this.resultsWrapperTarget.classList.remove('d-none')

      this.loadingValue = true
    })

    this.reportFrameTarget.addEventListener('turbo:frame-render', async (_event) => {
      this.loadingValue = false
    })
  }

  resultsTableRowClickedCallback(_event) {
    this.loadingPartitionTableValue = true

    this.partitionTableEl().classList.add('d-none')

    const reportContainerEl = document.getElementById('report-container')
    reportContainerEl.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  partitionTableCardTargetConnected() {
    this.loadingPartitionTableValue = false

    this.partitionTableEl().classList.remove('d-none')
  }

  partitionTableEl() {
    return document.getElementById('partition-table-container')
  }

  loadingValueChanged() {
    if (this.loadingValue) {
      this.loadingTarget.classList.remove('d-none')
      this.resultsTarget.classList.add('d-none')
    } else {
      this.loadingTarget.classList.add('d-none')
      this.resultsTarget.classList.remove('d-none')
    }
  }

  loadingPartitionTableValueChanged() {
    if (this.loadingPartitionTableValue) {
      this.loadingPartitionTableTarget.classList.remove('d-none')
    } else {
      this.loadingPartitionTableTarget.classList.add('d-none')
    }
  }
}
