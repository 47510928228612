// convert e.g. 2.75 to 2h 45mins or 2 hours 45 minutes if options.long set
const hoursAndMinutes = (hours, options) => { 
  let decimal_hours = parseInt(hours)
  let minutes_rest = Math.round((hours - decimal_hours) * 60)

  let res = []

  if(decimal_hours > 0 || (decimal_hours == 0 && minutes_rest == 0)) {
    let hours_str = decimal_hours
    if(options && options.long) {
      hours_str += decimal_hours > 1 || decimal_hours == 0 ? ' hours' : 'hour'
    } else {
      hours_str += 'h'
    }
    res.push(hours_str)
  }

  if(minutes_rest > 0) {
    let minutes_str = minutes_rest
    if(options && options.long) {
      minutes_str += minutes_rest > 1 || minutes_rest == 0 ? ' minutes' : 'minute'
    } else {
      minutes_str += 'm'
    }
    res.push(minutes_str)
  }

  return res.join(' ')
}

export default hoursAndMinutes
