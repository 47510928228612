export default function ExpandedHeaderTemplate(data) {
  if (data.scheduled) {
    return `
      <div class="header">
        ${data.start_time} - ${data.end_time}
      </div>
    `
  } else {
    return ''
  }
}