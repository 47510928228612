import { Controller } from '@hotwired/stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  connect() {
    let options = {}

    if(this.element.closest('.modal')) {
      options = { container: document.querySelector('.modal') }
    }

    const clipboard = new ClipboardJS(this.element, options)

    clipboard.on('success', function(e) {
      const prevHTML = e.trigger.innerHTML
      e.trigger.innerHTML = 'Copied!'

      setTimeout(function() {
        e.trigger.innerHTML = prevHTML
      }, 2000)
  
      e.clearSelection()
    })
  }
}
