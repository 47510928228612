import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hour", "min" ]

  click(e) {
    if (e.target == this.minTarget) {
      this.minTarget.select()
    } else {
      this.hourTarget.select()
    }
  }
}
