import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
//import Rails from '@rails/ujs'

export default class extends Controller {
  static values = { confirmationUrl: String, modalSelector: String }

  // connect() {
  //   console.log('form--confirmation-modal | connect', this.confirmationUrlValue,  this.modalSelectorValue )
  // }

  submitClick(event) {
    //console.log('form--confirmation-modal | submitClick')

    event.stopPropagation()
    event.preventDefault()

    this._fetchAndDisplayModal()

    //Rails.fire(this.element, 'submit')
  }

  dispatchEventFormUnsavedChangesSkipCheck() {
    //console.log('form--confirmation-modal | dispatchEventFormUnsavedChangesSkipCheck')
    const event_detail = { value: true }
    const event = new CustomEvent('form_unsaved_changes:skip_check', { detail: event_detail })

    window.dispatchEvent(event)
  }

  _fetchAndDisplayModal() {
    //console.log('form--confirmation-modal | _fetchAndDisplayModal', this.confirmationUrlValue)

    fetch(this.confirmationUrlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  receiveModalConfirm(_event) {
    //console.log('form--confirmation-modal | receiveModalConfirm()', _event.detail)

    this.element.submit()
  }
}
