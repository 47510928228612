Ready(function() {
  clipboard = new ClipboardJS('.copy-field')
  clipboard.on('success', function(e) {
    $(e.trigger).html("Copied!")

    setTimeout(function() {
      $(e.trigger).html("Copy")
    }, 2000);

    e.clearSelection();
  });
})
