import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    if(localStorage['timetable_tab']) {
      history.replaceState(null, null, localStorage['timetable_tab'])
    }

    const hash = location.hash ? location.hash : '#tab-all-activities'
    const href = hash.replace('tab-', '')

    let el = this.element.querySelector(`a[data-bs-toggle="tab"][href = '${href}']`)
    if(!el) {
      el = this.element.querySelector('[data-bs-toggle="tab"]') // grab first tab
    }

    const tab = new bootstrap.Tab(el)
    tab.show()

    this.element.addEventListener('show.bs.tab', (event) => {
      const hash = event.target.hash
      location.hash = hash.replace('#', '#tab-')

      // const tab = new bootstrap.Tab(event.target)
      // tab.show()

      localStorage['timetable_tab'] = location.hash
    })
  }
}
