import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTarget.addEventListener('confirm:complete', this.handleConfirmComplete.bind(this));
    this.buttonTarget.addEventListener('ajax:success', this.handleAjaxSuccess.bind(this));
    this.buttonTarget.addEventListener('ajax:error', this.handleAjaxError.bind(this));
  }

  handleConfirmComplete(event) {
    const [response] = event.detail;
    if (response) {
      this.buttonTarget.textContent = 'Inviting...';
      this.buttonTarget.classList.add('disabled');
    }
  }

  handleAjaxSuccess(event) {
    this.buttonTarget.textContent = 'Invited';
  }

  handleAjaxError(event) {
    alert("Error: Could not send invitation");
  }
}
