// responsible for features in job content edit form, Categorisation fieldset
// Notice: Tenant may have not set subcategories

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['categorySelect', 'subcategorySelect', 'activitySelect']
  static values = { category: String, subcategory: String, activity: String }

  connect() {
    document.addEventListener('tomselect:initialized', (e) => {
      const el = document.querySelector(e.detail.selector)

      if(this.hasSubcategorySelectTarget && el == this.subcategorySelectTarget) {
        this.subcategorySelectRemoveOptions()
      } else if (el == this.activitySelectTarget) {
        this.activitySelectRemoveOptions()
      }
    })
  }

  onChangeCategory(event) {
    this.categoryValue = event.target.value

    if(this.hasSubcategorySelectTarget) {
      this.subcategorySelectTarget.tomselect.setValue('', true)
      this.subcategorySelectTarget.selectedIndex = null

      this.subcategorySelectTarget.tomselect.clearOptions() // removes all unselected options from the control
      this.subcategorySelectTarget.tomselect.sync(true) // sync from original select because some options might be changed
      //this.activitySelectTarget.tomselect.clear() // clear selection, not needed because clerOptions does it
      this.subcategorySelectRemoveOptions()

      this.subcategorySelectTarget.tomselect.setValue('', true)
    }

    this.activitySelectRefresh()

    // received by patient_contacts_controller.js
    this.dispatch('category-changed', { detail: { category: this.categoryValue } })
  }

  onChangeSubcategory(event) {
    this.subcategoryValue = event.target.value
    this.activityValue = ''

    this.activitySelectRefresh()

    // received by patient_contacts_controller.js
    this.dispatch('subcategory-changed', { detail: { subcategory: this.subcategoryValue } })
  }

  activitySelectRefresh() {
    this.activitySelectTarget.tomselect.setValue('', true)
    this.activitySelectTarget.selectedIndex = null

    this.activitySelectTarget.tomselect.clearOptions()
    this.activitySelectTarget.tomselect.sync(true)

    this.activitySelectRemoveOptions()

    this.activitySelectTarget.tomselect.setValue('', true)
  }

  subcategorySelectRemoveOptions() {
    this.subcategorySelectTarget.querySelectorAll('option').forEach((o) => {
      if(!o.value) {
        return
      }

      const parent_categories = o.dataset.parentCategories.split('|')

      if(parent_categories.indexOf(this.categoryValue) == -1) {
        this.subcategorySelectTarget.tomselect.removeOption(o.value, true)
      }
    })
  }

  activitySelectRemoveOptions() {
    this.activitySelectTarget.querySelectorAll('option').forEach((o) => {
      if(!o.value) {
        return
      }

      const parent_categories = o.dataset.parentCategories.split('|')
      const benchmarks = this.hasSubcategorySelectTarget ? o.dataset.benchmarks.split('|') : []

      if(parent_categories.indexOf(this.categoryValue) == -1 ||
        (this.hasSubcategorySelectTarget && benchmarks.indexOf(this.subcategoryValue) == -1)) {
          this.activitySelectTarget.tomselect.removeOption(o.value, true)
      }
    })
  }
}
