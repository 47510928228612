import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['parent', 'child']

  connect() {
    this.childTargets.map(x => x.checked = true)
    this.parentTarget.checked = true
  }

  toggleChildren() {
    if (this.parentTarget.checked) {
      this.childTargets.map(x => x.checked = true)
    } else {
      this.childTargets.map(x => x.checked = false)
    }
  }
}
