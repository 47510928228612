import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitEnd(event) {
    if(event.detail.success) {
      const form = event.detail.formSubmission.formElement

      form.reset()

      // clear form errors if any
      _.each(form.getElementsByClassName('error'), (el) => el.innerHTML = null)

      // hide unsaved changed prompt if user just clicked Add/Save
      form.querySelector('.form-unsaved-changes-prompt').classList.add('d-none')
    }
  }
}
