import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'clinic', 'theatre', 'clinicPopover', 'theatrePopover']
  static values = { category: String, subcategory: String }

  connect() {
    this.parseSubcategoryValue()
    this.setPatientContactsVisibility()
  }

  // event from categorisation_controller.js
  onChangeCategory(event) {
    this.categoryValue = event.detail.category
  }

  categoryValueChanged() {
    if(this.hasContainerTarget) {
      if(this.categoryValue == 'DCC') {
        //this.containerTarget.classList.remove('d-none')
      } else {
        this.containerTarget.classList.add('d-none')

        this.theatreTarget.classList.add('d-none')
        this.clinicTarget.classList.add('d-none')

        this.theatrePopoverTarget.classList.add('d-none')
        this.clinicPopoverTarget.classList.add('d-none')
      }
    }
  }

  // event from categorisation_controller.js
  onChangeSubcategory(event) {
    this.subcategoryValue = event.detail.subcategory
    this.parseSubcategoryValue()
    this.setPatientContactsVisibility()
  }

  setPatientContactsVisibility() {
    if(!this.hasClinicTarget || !this.hasTheatreTarget) {
      return
    }

    if(this.isClinic()) {
      this.containerTarget.classList.remove('d-none')

      this.theatreTarget.classList.add('d-none')
      this.clinicTarget.classList.remove('d-none')

      if (this.hasClinicPopoverTarget) {
        this.clinicPopoverTarget.classList.remove('d-none')
      }
      if (this.hasTheatrePopoverTarget) {
        this.theatrePopoverTarget.classList.add('d-none')
      }

      this.theatreTarget.querySelectorAll('input').forEach(input => input.disabled = true)
      this.clinicTarget.querySelectorAll('input').forEach(input => input.disabled = false)

    } else if (this.subcategoryValue.startsWith('operating')) {
      this.containerTarget.classList.remove('d-none')

      this.theatreTarget.classList.remove('d-none')
      this.clinicTarget.classList.add('d-none')

      if (this.hasClinicPopoverTarget) {
        this.clinicPopoverTarget.classList.add('d-none')
      }
      if (this.hasTheatrePopoverTarget) {
        this.theatrePopoverTarget.classList.remove('d-none')
      }

      this.theatreTarget.querySelectorAll('input').forEach(input => input.disabled = false)
      this.clinicTarget.querySelectorAll('input').forEach(input => input.disabled = true)
    } else {
      this.containerTarget.classList.add('d-none')

      this.theatreTarget.classList.add('d-none')
      this.clinicTarget.classList.add('d-none')

      if (this.hasClinicPopoverTarget) {
        this.clinicPopoverTarget.classList.add('d-none')
      }
      if (this.hasTheatrePopoverTarget) {
        this.theatrePopoverTarget.classList.add('d-none')
      }

      this.theatreTarget.querySelectorAll('input').forEach(input => input.disabled = true)
      this.clinicTarget.querySelectorAll('input').forEach(input => input.disabled = true)
    }
  }

  parseSubcategoryValue() {
    this.subcategoryValue = this.subcategoryValue.replace(/\-/, '').toLowerCase()
  }

  isClinic() {
    return this.subcategoryValue.startsWith('clinical diagnostic') || this.subcategoryValue.startsWith('outpatient')
  }
}
