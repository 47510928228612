import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["section"];
  connect() {
    this.showFirstSection();
  }

  showFirstSection() {
    const firstSection = this.sectionTargets[0];
    if (firstSection) {
      const navElement = document.querySelector(`[href="#${firstSection.id}"]`);
      if (navElement) {
        navElement.click(); // Simulate a click on the nav element
      }
    }
  }

  toggle(event) {
    this.hideAllSections();
    const sectionId = event.currentTarget.getAttribute("href").substring(1);

    const section = this.sectionTargets.find(
      (section) => section.id === sectionId
    );

    if (section) {
      section.classList.toggle("hidden");
    }
  }

  hideAllSections() {
    this.sectionTargets.forEach((section) => {
      section.classList.add("hidden");
    });
  }
}
