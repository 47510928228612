Ready(function() {
  // Boilerplate Markup can eventually be replaced with and this removed
  // :code
  //    .some-haml
  //      YEAH
  //
  $('.sa-code-snippet').each(function() {
    if ($(this).parents('.sa-code-snippet-wrapper').length == 0) {
      $(this).wrap('<div class="sa-code-snippet-wrapper"></div>')
    }
    if ($(this).find('button.copy-code-button').length == 0) {
      $(this).prepend($('<button class="copy-code-button" type="button">Copy</button>'))
    }
  })

  clipboard = new ClipboardJS('.copy-code-button', {
    target: function(trigger) {
      return $(trigger).parent().find('code')[0]
    }
  })

  clipboard.on('success', function(e) {
    $(e.trigger).html("Copied!")

    setTimeout(function() {
      $(e.trigger).html("Copy")
    }, 2000);

    e.clearSelection();
  });
})
