if (window.Ready === undefined) {
  Ready = function(func) {
    $(document).on('page:change', func)     // old Turbolinks
    $(document).on('turbolinks:load', func) // new Turbolinks
  }
  $(document).ready(function() {
    if (typeof(Turbolinks) == 'undefined' || !Turbolinks.supported) {
      $(document).trigger('turbolinks:load')
    }
  });
}
