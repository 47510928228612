// used in Online Appraisals / Section3 / Appraisal Details

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'secondAppraiserRequired', 'additionalAppraiserRequired', 'leadAppraiserWarning', 'secondAppraiserWarning', 'additionalAppraiserWarning' ]
  static values = { appraiserConfirmation: Boolean, secondAppraiserConfirmation: Boolean, additionalAppraiserConfirmation: Boolean }

  leadAppraiserChanged() {
    if (!this.appraiserConfirmationValue) {
      return
    }

    this.leadAppraiserWarningTarget.classList.remove('d-none')
  }

  secondAppraiserChanged() {
    if (!this.secondAppraiserConfirmationValue || !this.secondAppraiserRequiredTarget.checked) {
      return
    }

    this.secondAppraiserWarningTarget.classList.remove('d-none')
  }

  additionalAppraiserChanged() {
    if (!this.additionalAppraiserConfirmationValue || !this.additionalAppraiserRequiredTarget.checked) {
      return
    }

    this.additionalAppraiserWarningTarget.classList.remove('d-none')
  }
}
