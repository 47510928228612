import { ExpandedHeaderTemplate, SummaryTemplate} from "./templates.js"

export default function PopoverTemplate(data) {
  const pas_html = `<div class="header">${data.pas || 0} PAs${data.impacted_pas ? ' *' : ''}</div>`

  return `
    <div class="activity ${data.categorisation.toLowerCase()}">
      <div class="category header">
        ${data.categorisation}
      </div>
      ${ExpandedHeaderTemplate({
        scheduled: data.scheduled,
        start_time: data.start_time,
        end_time: data.end_time
      })}
      <div class="header">
        ${data.hours_long}${data.impacted_hours ? ' *' : ''}
      </div>
      ${pas_html}
      <div>
        ${SummaryTemplate({
          summary: data.summary
        })}
      </div>
    </div>`
}