// disable activity form when complete
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    noResultsText: { type: String, default: 'Not found' },
    width: { type: String, default: '100%' }
    // allowSingleDeselect: { type: Boolean, default: true }
  }

  connect(){
    $(this.element).chosen({
      no_results_text: this.noResultsTextValue,
      width: this.widthValue,
      allow_single_deselect: true
    })
  }
}
