// used in app/views/edocs

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'templateEdocId', 'fragment' ]
  static values = { standalone: { type: Boolean, default: false } } // standalone used only in online job plans at the moment

  fragmentTargetConnected(target) {
    if(this.standaloneValue) {
      return
    }

    const edocAction = target.dataset.edocAction

    // render and insert hidden field with edoc id only if fragment connected after some action no on load
    if(edocAction) {
      const edocId = target.dataset.edocId
      this.edocPrepared(edocId, edocAction)
    }
  }

  // TODO remove edoc_action if not needed here
  edocPrepared(edoc_id, edoc_action) {
    const content = this.prepareEdocIdField(edoc_id, edoc_action)
    const container = this.getIdFieldContainer(edoc_id)
    container.innerHTML = content
  }

  prepareEdocIdField(edoc_id, edoc_action) {
    if(this.hasTemplateEdocIdTarget) {
      const template_content = this.templateEdocIdTarget.innerHTML

      return template_content.replace(/EDOC_ID/g, edoc_id) //.replace(/EDOC_ACTION/g, edoc_action)
    }
  }

  getFragment(id) {
    return this.fragmentTargets.length > 1 ? this.fragmentTargets.find(ft => ft.dataset.edocId == id) : this.fragmentTarget
  }

  getIdFieldContainer(id) {
    return this.getFragment(id).querySelector('.attachment-id-container')
  }
}
