import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = { userSelectBaseUrl: String }
  static targets = ['fromTenant', 'toTenant', 'fromUser', 'toUser']

  fromTenantChanged(event) {
    const url = `${this.userSelectBaseUrlValue}?tenant_id=${this.fromTenantTarget.value}&prefix=from`

    // TODO: can be simplified when updated to turbo 8.x
    this.#renderTurboStreamFromUrl(url, 'user-transfer-from-user-select-container')
  }

  toTenantChanged(event) {
    const url = `${this.userSelectBaseUrlValue}?tenant_id=${this.toTenantTarget.value}&prefix=to`

    // TODO: can be simplified when updated to turbo 8.x
    this.#renderTurboStreamFromUrl(url, 'user-transfer-to-user-select-container')
  }

  fromUserChanged() {
    this.#preselectToUser()
  }

  toUserTargetConnected() {
    this.#preselectToUser()
  }

  #preselectToUser() {
    if (!this.hasFromUserTarget || !this.fromUserTarget.value || !this.hasToUserTarget || this.toUserTarget.value) {
      return
    }

    const fromUserFullName = this.fromUserTarget.options[this.fromUserTarget.selectedIndex].text.replace(' (dormant)', '')
    const matchingOption = Array.from(this.toUserTarget.options).find(option => option.text.includes(fromUserFullName))

    if (!matchingOption) {
      return
    }

    const toUserTs = this.toUserTarget.tomselect
    toUserTs.setValue(matchingOption.value)
  }

  #renderTurboStreamFromUrl(url) {
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(ts => {
      Turbo.renderStreamMessage(ts)
    })
  }
}
