import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  disableLink() {
    this.element.classList.add("disabled");
    this.element.removeEventListener("click", this.handleClick);
  }

  connect() {
    this.handleClick = this.disableLink.bind(this);
    this.element.addEventListener("click", this.handleClick);
  }
}

