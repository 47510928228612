import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { containerSelector: String }

  submitEnd(event) {
    if(!event.detail.success) {
      const container = document.querySelector(this.containerSelectorValue)
      container.scrollTop = 0
    }
  }
}
