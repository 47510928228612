import { Controller } from '@hotwired/stimulus'
import * as d3 from 'd3'

// inspired by
// https://observablehq.com/@d3/sunburst/2
// https://observablehq.com/@kerryrodden/sequences-sunburst

export default class extends Controller {
  static targets = ['chart', 'breadcrumb']
  static values = { data: Object }

  static CHART_WIDTH = 2000 // 750
  static CHART_HEIGHT = 1700 // 450
  static BREADCRUMB_WIDTH = 750
  static BREADCRUMB_HEIGHT = 30
  static BREADCRUMB_ITEM_WIDTH = 230
  static BREADCRUMB_ITEM_HEIGHT = 30
  static BREADCRUMB_ITEM_SPACE = 3
  static BREADCRUMB_ITEM_TIP = 10

  static TAU = 2 * Math.PI

  connect() {
    this.chart()
  }

  customValue(d) {
    if (!d.parent) {
      return 100
    } else {
      const sum_of_all_siblings = d.parent.children.reduce((memo, ch) => memo + ch.data.internal_value, 0)
      const relative_to_sibling_percentage = d.data.internal_value / sum_of_all_siblings
      return this.customValue(d.parent) * relative_to_sibling_percentage
    }
  }

  chart() {
    const that = this
    const radius = Math.min(this.cw, this.ch) / 2

    const data = this.dataValue

    this.color = d3.scaleOrdinal(d3.schemeCategory10)

    this.luminance = d3.scaleSqrt()
            .domain([0, 1e6])
            .clamp(true)
            .range([90, 30])

    const partition = d3.partition()
      .size([this.constructor.TAU, radius])

    const hierarchy = d3.hierarchy(data)
      //.sum(d => that.node_percentage(d))
      .sum((d) => {
        //return d.internal_value
        return (d.children && d.children.length > 0 ? 0 : d.internal_value)
      })
      .sort((a, b) => b.internal_value - a.internal_value)
      .eachBefore(d => {
        d.value = that.customValue(d)
      })

    this.root = partition(hierarchy)
    const nodes = this.root.descendants().slice(1)

    const arc = d3.arc()
      .startAngle(d => d.x0)
      //.endAngle(d => d.x1)
      .endAngle(d => d.x0 + (d.x1 - d.x0) - (0.01 / (d.depth + 0.5))) // d.x1 - d.x0 is former d.dx, so delta x
      //.padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius / 5)
      .padAngle(.01) // space between pie sclices, radian one
      //.innerRadius(d => d.y0)
      //.outerRadius(d => d.y1 - 1)
      .innerRadius(d => radius / 5 * (d.depth + 1) - 1)
      .outerRadius(d => radius / 5 * (d.depth + 2) - 4) // (4) space bettween slices, arc one

    const chartContainer = d3.select(this.chartTarget)

    this.svg = chartContainer.append("svg")

    this.svg
      .attr("viewBox", [-this.cw / 2, -this.ch / 2, this.cw, this.ch])
      .attr("style", "max-width: 100%; height: auto;")
      .attr("id", "container")

    // text in the middle of the chart
    this.centLabel = this.svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("fill", "#888")
      .style("visibility", "hidden")
      .style("font-family", "Roboto")
      .style("font-weight", "300") // 300 is the weight for Roboto Light
      .attr("class", "activity-info")

    this.centLabel
      .append("tspan")
      .attr("id", "chart-percentage")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", "-0.1em")
      .attr("font-size", "8em")

    this.centLabel
      .append("tspan")
      .attr("id", "chart-hours-or-pas")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", "1em")
      .attr("font-size", "6em")

    // pie slices
    const path = this.svg
      .append("g")
      .selectAll('path')
      .data(nodes.filter(d => {
        // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
        return d.depth && d.x1 - d.x0 > 0.005 // 0.005 radians = 0.29 degrees
      }))
      .join("path")
      .attr("fill", d => {
        while (d.depth > 1) d = d.parent
        return that.fill(d)
      })
      .attr('class', d => d.data.custom_css_class ? d.data.custom_css_class : null)
      .attr("d", arc)

    // const mousearc = d3
    //   .arc()
    //   .startAngle(d => d.x0)
    //   .endAngle(d => d.x1)
    //   .innerRadius(d => Math.sqrt(d.y0))
    //   .outerRadius(radius)

    this.svg.append("g")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseleave", () => {
        that.chartMouseleave()
      })
      .selectAll('path')
      .data(nodes.filter(d => {
        // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
        return d.depth && d.x1 - d.x0 > 0.005 // 0.005 radians = 0.29 degrees
      }))
      .join("path")
      .attr("d", arc)
      .attr('data-id', d => d.data.id)
      .on("mouseenter", (_event, d) => {
        that.chartMouseover(d)

        that.dispatch('slice-mouseover', { detail: { id: d.data.id } })
      })
      .on('click', (_event, d) => {
        that.dispatch('slice-clicked', { detail: { id: d.data.id } })
      })

    if(this.hasBreadcrumbTarget) {
      this.initializeBreadcrumbTrail()
    }
  }

  chartMouseover(d) {
    const sequence = d
          .ancestors()
          .reverse()
          .slice(1)

    const percentage = this.nodePercentage(d)

    this.svg.selectAll("path").attr("fill-opacity", node => {
      return sequence.indexOf(node) >= 0 ? 1.0 : 0.3
    })

    if(this.hasBreadcrumbTarget) {
      this.updateBreadcrumbs(sequence, percentage)
    }

    this.updateCentralLabel(sequence, percentage)
  }

  updateCentralLabel(sequence, percentage) {
    this.centLabel.select("#chart-percentage").text(percentage + "%")

    const current = sequence[sequence.length - 1]
    const count = Math.round(current.data.internal_value)
    const units = current.data.data_label
    this.centLabel.select("#chart-hours-or-pas").text(`${count} ${units}`)

    this.centLabel.style('visibility', '')
  }

  chartMouseleave() {
    this.svg.selectAll("path").attr("fill-opacity", 1)
    this.bcNode.style.visibility = 'hidden'
    this.centLabel.style('visibility', 'hidden')
  }

  // callbacks received from reports--advanced-job-plan-results-table controller
  rowMouseoverCallback(event) {
    const id = event.detail.id

    const selectedPath = this.svg.selectAll("path").filter(d => d.data.id === id)
    if(selectedPath.empty()) {
      return
    }
    const d = selectedPath.datum()

    this.chartMouseover(d)
  }

  rowMouseoutCallback(_event) {
    this.chartMouseleave()
  }

  nodePercentage(d) {
    return (d.value).toFixed(1)
  }

  fill(d) {
    let p = d
    while (p.depth > 1) p = p.parent

    let c = d3.lab(this.color(p.data.name))
    c.l = this.luminance(1)

    return c
  }

  // Generate a string that describes the points of a breadcrumb polygon.
  breadcrumbPoints(d, i) {
    let points = []

    points.push("0,0")
    points.push(this.biw + ",0")
    points.push(this.biw + this.bit + "," + (this.bih / 2))
    points.push(this.biw + "," + this.bih)
    points.push("0," + this.bih)
    if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
      points.push(this.bit + "," + (this.bih / 2))
    }

    return points.join(" ")
  }

  initializeBreadcrumbTrail() {
    const svg = d3.select(this.breadcrumbTarget).append("svg")
      .attr("viewBox", `0 0 ${this.bw} ${this.bh}`)
      .attr("id", "trail")
      .style("font", "12px sans-serif")
      .style("margin-bottom", "20px")

    this.bcNode = svg.node()
  }

  updateBreadcrumbs(sequence, _percentage) {
    const that = this

    const gt = d3.select("#trail")

    const g = gt.selectAll("g")
      .data(sequence)
      .join("g")
      .attr("transform", (d, i) => `translate(${i * this.biw}, 0)`)

    // cleanup existing nodes
    //g.exit().remove()
    g.selectAll("*").remove()

    g.append("polygon")
      .attr("points", (d, i) => that.breadcrumbPoints(d, i))
      .attr("fill", (d) => that.fill(d))
      .attr('class', (d) => d.data.custom_css_class ? d.data.custom_css_class : null)
      .attr("stroke", "white")

    g.append("text")
      .attr("x", (this.biw + 10) / 2)
      .attr("y", this.bih / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text(d => d.data.name)

    gt.style("visibility", null)
  }

  // getters

  get cw(){
    return this.constructor.CHART_WIDTH
  }

  get ch(){
    return this.constructor.CHART_HEIGHT
  }

  get bw(){
    return this.constructor.BREADCRUMB_WIDTH
  }

  get bh(){
    return this.constructor.BREADCRUMB_HEIGHT
  }

  get biw(){
    return this.constructor.BREADCRUMB_ITEM_WIDTH
  }

  get bih(){
    return this.constructor.BREADCRUMB_ITEM_HEIGHT
  }

  get bis(){
    return this.constructor.BREADCRUMB_ITEM_SPACE
  }

  get bit(){
    return this.constructor.BREADCRUMB_ITEM_TIP
  }
}