// used in app/views/edocs

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form', 'sort', 'filter' ]

  formTargetConnected(target) {
    target.reset()
  }

  // reseting form does not reset chosen selects

  sortTargetConnected(target) {
    $(target).chosen({ no_results_text: 'sort option not found', width: '100%' })
  }

  filterTargetConnected(target) {
    $(target).chosen({ no_results_text: 'filter option not found',
                    placeholder_text: 'Select filter...', width: '100%' })
  }
}
