// when attached to turbo form it clears params with empty values and removes 'commit' and 'utf8' params

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-start', (event) => {
      let searchParams = event.detail.formSubmission.fetchRequest.url.searchParams

      const keys = Object.values(Array.from(new Set(searchParams.keys()))) // Set used to get unique keys

      for (const key of keys) {
        const val = key.endsWith('[]') ? val = searchParams.getAll(key) : searchParams.get(key)
        //console.log('key', key, 'val', val)
        if(!val || val == '' || val == [] || val == ['']) {
          searchParams.delete(key)
        }
      }

      searchParams.delete('utf8')
      searchParams.delete('commit')
    })
  }
}
