import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static values = {
    selectedIds: Array,
    dragAndDrop: { type: Boolean, default: false },
    dropdownInput: { type: Boolean, default: false },
    dropdownParent: { type: String, default: '' },
    allowEmptyOption: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    usePopper: { type: Boolean, default: false },
    useCustomOptionRender: { type: Boolean, default: false },
    useCustomItemRender: { type: Boolean, default: false },
    useRedirect: { type: Boolean, default: false },
    closeAfterSelect: { type: Boolean, default: true },
    dispatchEvent: { type: Boolean, default: false },
    refreshThrottle: { type: Number, default: null }
  }

  connect() {
    // skip TomSelect in pdf views, just regular select will be rendered
    const metaEl = document.querySelector("meta[name='pdf']")
    if(metaEl && metaEl.getAttribute('content') == 'true') {
      return
    }

    const el = this.element
    const htmlEl = document.getElementsByTagName('html')[0]

    let config = {
      maxOptions: null,
      hidePlaceholder: true, // hides placeholder when some options are already selected
	    duplicates: true,
      closeAfterSelect: this.closeAfterSelectValue
    }

    if(this.allowEmptyOptionValue) {
      config.allowEmptyOption = true
    }

    const context = this

    if(this.usePopperValue) {
      const popper_options = {
        modifiers: [
          {
            name: 'flip',
            options: {
              flipVariations: false
            }
          }
        ]
      }

      config.onInitialize = function() {
        // this in context of this function is instance of TomSelect
        this.popper = createPopper(this.control, this.dropdown, popper_options)
      }
    }

    config.onDropdownOpen = function() {
      // this in context of this function is instance of TomSelect
      if(this.usePopperValue) {
        this.popper.update()
      }

      htmlEl.setAttribute('data-disable-scroll', 'true')

      // add data attribute to parent table responsive of current TomSelect instance
      let tableResponsiveEl = context.element.closest('.table-responsive')
      if(tableResponsiveEl) {
        tableResponsiveEl.setAttribute('data-has-tom-select-opened', true)
      }
    }

    config.onDropdownClose = function() {
      htmlEl.removeAttribute('data-disable-scroll')

      let tableResponsiveEl = context.element.closest('.table-responsive')
      if(tableResponsiveEl) {
        tableResponsiveEl.removeAttribute('data-has-tom-select-opened')
      }
    }

    // Position Dropdown
    if(this.dropdownParentValue && this.dropdownParentValue != '') {
      // use dropdownParent selector passed with data
      config.dropdownParent = this.dropdownParentValue
    } else if(this.inTableResponsive() && !this.inModal()) {
      // if dropdown rendered in .table-responsive but not in modal use body tag
      config.dropdownParent = 'body'
    }

    if(el.multiple) {
      config.plugins = [
        'dropdown_input',
        'remove_button'
      ]

      if(this.inFilter()) {
        config.plugins.push('input_autogrow')
      }

      if(this.dragAndDropValue) {
        config.plugins.push('drag_drop')
      }

      // workaround to keep custom order of items, independent of order of available options
      if(this.selectedIdsValue && this.selectedIdsValue.length > 0) {
        config.items = this.selectedIdsValue
      }

      if(!el.getAttribute('placeholder')) {
        config.placeholder = 'Select some options'
      }
    } else { // single select
      config.plugins = ['clear_button']

      if(this.dropdownInputValue) {
        config.plugins.push('dropdown_input')
      }

      if(!el.getAttribute('placeholder')) {
        config.placeholder ='Select an option'
      }

      // redirect page to the url specified as data-tomselect-redirect-url option attribute
      if(this.useRedirectValue) {
        config.onChange = function(value) {
          const originalSelect = this.input
          const selectedOption = originalSelect.querySelector(`option[value="${value}"]`)

          const redirectURL = selectedOption.getAttribute('data-tomselect-redirect-url')
          if(redirectURL) {
            window.location.href = redirectURL
          }
        }
      }
    }

    if(this.allowCreateValue) {
      config.create = true
      config.createOnBlur = true

      config.createFilter = function(input) {
        input = input.toLowerCase()
        return !(input in this.options)
      }
    }

    // custom render for options
    if(this.useCustomOptionRenderValue) {
      config.render = {
        option: function(data, escape) {
          return `<div value="${data.value}"` +
            (data.extraCssClass ? ` class="${data.extraCssClass}">` : '>') +
            escape(data.text) +
            '</div>'
        }
      }
    }

    // custom render for selected option
    if(this.useCustomItemRenderValue) {
      config.render.item = function(data, escape) {
        return `<div value="${data.value}"` +
            (data.extraCssClass ? ` class="${data.extraCssClass}">` : '>') +
            escape(data.text) +
            '</div>'
      }
    }

    if(this.refreshThrottleValue == 0 || this.refreshThrottleValue) {
      config.refreshThrottle = this.refreshThrottleValue
    }

    if(this.dispatchEventValue) {
      config.onInitialize = () => {
        const ts_initialized_event = new CustomEvent('tomselect:initialized', { detail: { selector: `#${el.getAttribute('id')}` } })
        document.dispatchEvent(ts_initialized_event)
      }
    }

    this.tomSelect = new TomSelect(el, config)
  }

  inTableResponsive() {
    return !!this.element.closest('.table-responsive')
  }

  inCardBody() {
    return !!this.element.closest('.card-body')
  }

  inModal() {
    return !!this.element.closest('.modal')
  }

  inFilter() {
    return !!this.element.closest('.filters')
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy()
    }
  }
}
