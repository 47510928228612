// custom confirmations controller for job plan comments create/delete

import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = { confirmationUrl: String }

  connect() {
    //console.log('job-plan--comments | url | datatset', this.confirmationUrlValue, this.element.dataset)

    const deleteButtons = this.element.querySelectorAll('a[data-turbo-method=delete]')

    // assign confirmation modal to comment delete buttons
    _.each(deleteButtons, (el) => {
      el.setAttribute('data-action', 'click->job-plan--comments#showConfirmationModal')
      el.removeAttribute('data-turbo-confirm')
    })

    // assign confirmation to form submit
    const newCommentFormSubmit = this.element.querySelector('form#new_comment [type=submit]')
    newCommentFormSubmit.setAttribute('data-action', 'click->job-plan--comments#showConfirmationModal')

    addEventListener('modal:confirm:comment_delete', (event) => {
      //console.log('job-plan--comments | catched modal:confirm:comment_delete', event)
      this._receiveConfirmCommentDelete(event.detail.confirm_button)
    })

    addEventListener('modal:confirm:comment_create', (event) => {
      //console.log('job-plan--comments | catched modal:confirm:comment_create', event)
      this._receiveConfirmCommentSave(event.detail.confirm_button, 'create')
    })

    addEventListener('modal:confirm:comment_update', (event) => {
      //console.log('job-plan--comments | catched modal:confirm:comment_update', event)
      this._receiveConfirmCommentSave(event.detail.confirm_button, 'update')
    })

    addEventListener('turbo:frame-render', (event) => {
      //console.log('job-plan--comments | catched turbo:frame-render | event | event.detail.fetchResponse', event, event.detail.fetchResponse)

      // assign confirmation for form Save
      const editCommentFormSubmit = event.target.querySelector('form#edit_comment [type=submit]')
      //console.log('editCommentFormSubmit', editCommentFormSubmit)
      if(editCommentFormSubmit) {
        editCommentFormSubmit.setAttribute('data-action', 'click->job-plan--comments#showConfirmationModal')
      }
      //this._receiveConfirmCommentCreate(event.detail.confirm_button)
    })
  }

  showConfirmationModal(event) {
    event.preventDefault()

    //console.log('job-plan--comments | showConfirmationModal | event', event, event.srcElement.dataset.turboMethod)

    let action_name = null
    if(event.srcElement.dataset.turboMethod == 'delete') {
      action_name = 'delete'
    } else {
      const methodPatch = event.srcElement.form.querySelector("input[name='_method'][value='patch']")
      action_name = methodPatch ? 'update' : 'create'
    }
    this.deleteSourceHref = action_name == 'delete' ? event.srcElement.getAttribute('href') : null

    this._fetchAndDisplayModal(action_name)
  }

  _receiveConfirmCommentDelete(confirm_button) {
    //console.log('job-plan--comments | receiveConfirmCommentDelete', confirm_button, this.deleteSourceHref)

    const csrfToken = document.querySelector("[name='csrf-token']").getAttribute('content')

    let url = this.deleteSourceHref

    if(confirm_button == 'yes') {
      url += '&revoke_signatories=true'
    }

    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': csrfToken,
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  _receiveConfirmCommentSave(confirm_button, action_name) {
    //console.log('job-plan--comments | receiveConfirmCommentSave | confirm_button:', confirm_button, action_name)

    const form_query = `form#${action_name == 'create' ? 'new' : 'edit' }_comment`
    let form = this.element.querySelector(form_query)

    if(confirm_button == 'yes') {
      // add hidden field revoke_signatories with value true
      form.appendChild(this._prepareRevokeSignatoriesField())
    }

    Rails.fire(form, 'submit')
  }

  async _fetchAndDisplayModal(action_name) {
    await fetch(`${this.confirmationUrlValue}?action_name=${action_name}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  _prepareRevokeSignatoriesField() {
    let input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'revoke_signatories')
    input.setAttribute('value', 'true')
    return input
  }
}
