import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    $('.collapse').on('hidden.bs.collapse', function() {
      if (this.id) {
        localStorage[this.id] = 'true';
      }
    }).on('shown.bs.collapse', function() {
      if (this.id) {
        localStorage.removeItem(this.id);
      }
    }).each(function() {
      if (this.id && localStorage[this.id] === 'true' ) {
        $('.toggle-header').attr('aria-expanded','false')
        $(this).removeClass('show');
        $('.toggle-header').addClass('collapsed');
      }
    });
  }
}
