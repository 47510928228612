// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

import TextareaAutogrow from 'stimulus-textarea-autogrow'
application.register('textarea-autogrow', TextareaAutogrow)

import Sortable from 'stimulus-sortable'
application.register('sortable', Sortable)

// load local controllers
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// load SardCore controllers
const core = require.context('/vendor/engines/sard_core/app/javascript/controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(core))
