// category and subcategory select filter
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "category", 'benchmark', 'subcategory' ]

  connect(){
    this.toggleDccInputs();
    this.filterBenchmark();
    this.filterSubcategory();
    $('textarea').autosize({ append: "\n\n" });
  }

  onSelectCategory(event) {
    // clear selected benchmark and subcategory
    this.benchmarkTarget.value = [];
    this.filterBenchmark();
    this.subcategoryTarget.value = [];
    this.filterSubcategory();
    $('.category-error').empty();
    $('.subcategory-error').empty();
    $('.activity-error').empty();

    this.toggleDccInputs();
  }

  toggleDccInputs(){
    if(this.categoryTarget.value == 'DCC'){
      $('.dcc-input').show();
    }else{
      $('.dcc-input').hide();
    }
  }

  onSelectBenchmark(event) {
    // clear selected subcategory
    this.subcategoryTarget.value = [];
    this.filterSubcategory();
    $('.category-error').empty();
    $('.subcategory-error').empty();
    $('.activity-error').empty();
  }

  onSelectSubcategory(event) {
    $('.category-error').empty();
    $('.subcategory-error').empty();
    $('.activity-error').empty();
  }

  //

  filterBenchmark() {
    // (hide/show doesn't work on IE11 so need to set diplay instead)
    // hide all options
    $("#job_content_benchmark option").css('display', 'none');
    // show options for parent category
    $(`#job_content_benchmark option[data-parent=${this.categoryTarget.value}]`).css('display', 'block');

    $("#job_content_benchmark").trigger('chosen:updated');
  }

  filterSubcategory() {
    // (hide/show doesn't work on IE11 so need to set diplay instead)
    // hide all options
    $("#job_content_subcategorisation option").css('display', 'none');
    // show options for parent subcategory
    if(this.benchmarkTarget.value) {
      $(`#job_content_subcategorisation option[data-parent='${this.benchmarkTarget.value}'][data-parent-category='${this.categoryTarget.value}']`).css('display', 'block');
    }
    $("#job_content_subcategorisation").trigger('chosen:updated');
  }
}
