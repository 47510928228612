import { Controller } from '@hotwired/stimulus'
import * as d3 from 'd3'
// From https://gist.github.com/mbostock/4063318
// https://gist.github.com/GuilloOme/75f51c64c2132899d58d4cd6a23506d3 v4 ver

export default class extends Controller {
  static targets = ['chart']
  static values = { data: Object }

  static WIDTH = 960
  static HEIGHT = 136
  static CELL_SIZE = 17
  static COLOR_RANGE = ["#a6d96a", "#d9ef8b", "#ffffbf", "#fee08b", "#fdae61", "#f46d43", "#d73027", "#a50026"]
  static DEFAULT_FILL_COLOR = "#66bd63"

  connect() {
    this.chart()
  }

  chart() {
    const svg = d3.select(this.chartTarget)
      .selectAll("svg")
      //.data(d3.range(this.dataValue.start, this.dataValue.end + 1))
      .data(d3.range(this.dataValue.end, this.dataValue.start - 1, -1)) // most recent year first
      .join("svg")
      .attr("width", this.width())
      .attr("height", this.height())
      .append("g")
      .attr("transform", `translate(${(this.width() - this.cellSize() * 53) / 2},${this.height() - this.cellSize() * 7 - 1})`)

    svg.append("text")
      .attr("transform", `translate(-6,${this.cellSize() * 3.5})rotate(-90)`)
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "middle")
      .text(d => d)

    const rect = svg.append("g")
        .attr("fill", "none")
        .attr("stroke", "#ccc")
      .selectAll("rect")
      .data(d => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .enter().append("rect")
        .attr("width", this.cellSize())
        .attr("height", this.cellSize())
        .attr("x", d => d3.timeWeek.count(d3.timeYear(d), d) * this.cellSize())
        .attr("y", d => d.getDay() * this.cellSize())
        .datum(d3.timeFormat("%Y-%m-%d"))

    svg.append("g")
      .attr("fill", "none")
      .attr("stroke", "#000")
      .selectAll("path")
      .data(d => d3.utcMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .join("path")
      .attr("d", this.pathMonth.bind(this))

    const color = d3.scaleQuantize()
      .domain([this.dataValue.min, this.dataValue.max])
      .range(this.constructor.COLOR_RANGE)

    const data = d3.rollups(this.dataValue.data, v => v[0].leave, d => d.date)
      .reduce((obj, [key, value]) => {
        obj[key] = value
        return obj
      }, {})

    rect.attr("fill", d => (data[d] > 0 ? color(data[d]) : this.constructor.DEFAULT_FILL_COLOR))
      .append("title")
      .text(d => `${d}: ${data[d] || '0'}`)
  }

  pathMonth(t0) {
    const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0),
        d0 = t0.getDay(), w0 = d3.timeWeek.count(d3.timeYear(t0), t0),
        d1 = t1.getDay(), w1 = d3.timeWeek.count(d3.timeYear(t1), t1)

    return `M${(w0 + 1) * this.cellSize()},${d0 * this.cellSize()}
      H${w0 * this.cellSize()} V${7 * this.cellSize()}
      H${w1 * this.cellSize()} V${(d1 + 1) * this.cellSize()}
      H${(w1 + 1) * this.cellSize()} V${0}
      H${(w0 + 1) * this.cellSize()} Z`;
  }

  width() {
    return this.constructor.WIDTH
  }

  height() {
    return this.constructor.HEIGHT
  }

  cellSize() {
    return this.constructor.CELL_SIZE
  }
}
