import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { canEdit: { type: Boolean, default: true } }

  connect(){
    this._applyPopovers()

    if(!this.canEditValue) {
      this.disableForm()
    }

    // scroll to top when edit activity
    document.scrollingElement.scrollTo(0, 0)
  }

  _applyPopovers() {
    let popoverTriggerList = [].slice.call(this.element.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })
  }

  // TODO: rewrite to do not use jQuery
  // disable activity form when complete
  disableForm() {
    // Form inputs
    $("input").attr('disabled', true);
    $('textarea').attr('disabled', true);
    $(".clickable_td").css('pointer-events','none');

    // select boxes
    $('select').attr('disabled', true);
    $('select').trigger("chosen:updated");
    // Save
    $('input[type="submit"]').attr('disabled', true);
    // Cancel
    $(".btn-danger").attr('disabled', true);
  }
}
