import { TimetableView, ListTableView } from "./views.js"
import hoursAndMinutes from '../shared/hours_and_minutes'

export default class TableBuilderView {
  constructor(job_contents, options) {
    this.job_contents = job_contents
    this.options = options || {}

    this.all_job_contents = job_contents

    this.setMinStart()
    this.setMaxEnd()

    this.tables = this.defaultTables()
    this.template_data = {}

    this.buildTables(this.options.sort_method, this.options.search_query)
  }

  setMinStart() {
    const scheduledOnly = _.filter(this.job_contents, (jc) => { return jc.scheduled == true })
    if(scheduledOnly.length > 0) {
      this.minStart = _.sortBy(scheduledOnly, 'start_time')[0].start_time
      if(this.minStart.slice(-2) != '00') {
        this.minStart = `${this.minStart.split(':')[0]}:00` // change e.g. 7:45 to 7:00
      }
    }
  }

  setMaxEnd() {
    if (_.some(this.job_contents, function(job_content) { return job_content.scheduled && this.overlapsMidnight(job_content) }, this)) {
      this.maxEnd = '24:00'
    } else {
      this.maxEnd = _.sortBy(this.job_contents, 'end_time')[this.job_contents.length - 1].end_time
      if(this.maxEnd && this.maxEnd.slice(-2) != '00') { // change e.g. 16:45 to 17:00
        let h = parseInt(this.maxEnd.split(':')[0])
        this.maxEnd = `${h + 1}:00`
      }
    }
  }

  title(job_content) {
    if (!job_content.scheduled) {
      return 'Flexible'
    } else if (job_content.repeats === 'never') {
      return 'Never Repeats'
    } else if (job_content.repeats === 'daily') {
      return 'Every n Days'
    } else if(this.forWeekday(job_content)) {
      return job_content.day
    } else {
      return 'Other'
    }
  }

  search(search_query) {
    const searchFields = ['description', 'schedule_description', 'activity_speciality', 'activity_type', 'categorisation', 'employer',
                          'start_time', 'end_time', 'happening_hours', 'hours', 'happening_pas', 'pas',
                          'location', 'one_line_summary', 'summary', 'repeats' ]

    return _.filter(this.all_job_contents, (jc) => {
      return _.some(searchFields, (sf) => {
        let val = jc[sf] || ''
        if(val && (sf == 'pas' || sf == 'happening_pas')) {
          val = val.toFixed(3)
        }
        if(val && (sf == 'hours' || sf == 'happening_hours')) {
          val = hoursAndMinutes(val) + ' ' + hoursAndMinutes(val, { long: true })
        }
        return val.toString().toLowerCase().includes(search_query)
      })
    })
  }

  buildTables(sort_method, search_query) {
    let table_name
    const sortMethod = sort_method || 'chronological_order'

    this.sortMethod = sort_method

    // only for Drag&Drop
    this.sortableRows = sortMethod == 'order'

    if(sort_method != 'activity_type' && search_query != undefined) { // because '' may be also passed on reset seach field
      this.job_contents = this.search(search_query)
    }

    if(sortMethod == 'chronological_order') {
      this.job_contents = _.sortBy(this.job_contents, (jc) => Date.parse(jc.from_time))
    } else if (sortMethod == 'categorisation') {
      this.job_contents = _.sortBy(this.job_contents, (jc) => jc.categorisation)
    } else {
      this.job_contents = _.sortBy(this.job_contents, (jc) => jc.order)
    }

    // for medical job plans only
    _.each(this.job_contents, (function(job_content) {
      if(sortMethod == 'chronological_order') {
        if (job_content.scheduled) {
          if(this.forWeekday(job_content) && !_.isEmpty(job_content.happening_pas_and_hours_per_day)) {
            // Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday.
            _.each(job_content.happening_pas_and_hours_per_day, (function(data, table_name) {
              table_name = this.capitalizeFirstLetter(table_name)
              this.addTimetableElement(table_name, job_content, data.hours, data.pas)
            }), this)

          } else {
            // Every n Days, Never Repeats, Other.
            table_name = this.capitalizeFirstLetter(this.title(job_content))
            this.addTimetableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
          }
        } else {
          // Flexible.
          table_name = this.capitalizeFirstLetter(this.title(job_content))
          this.addListTableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
        }

      } else if (sortMethod == 'categorisation') {
        // Sorted by DCC, SPA etc.
        table_name = job_content.categorisation
        this.addListTableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
      } else if (sortMethod == 'order') {
        // Drag & Drop sort or custom timetables
        table_name = this.options.custom_table_name || 'Drag & Drop'
        this.addListTableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
      } else if (sortMethod == 'activity_type') {
        table_name = `${search_query} Activities`
        this.addListTableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
      }
    }), this)

    // TODO cleanup
    // old for non-medical job plans
    // _.each(job_contents, (function(job_content) {
    //   if (job_content.scheduled) {
    //     if (_.isEmpty(job_content.happening_pas_and_hours_per_day)) {
    //       // Every n Days, Never Repeats, Other.
    //       table_name = this.capitalizeFirstLetter(this.title(job_content))
    //       this.addTimetableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
    //     } else {
    //       // Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday.
    //       _.each(job_content.happening_pas_and_hours_per_day, (function(data, table_name) {
    //         table_name = this.capitalizeFirstLetter(table_name)
    //         this.addTimetableElement(table_name, job_content, data.hours)
    //       }), this)
    //     }
    //   } else {
    //     // Flexible.
    //     table_name = this.capitalizeFirstLetter(this.title(job_content))
    //     this.addListTableElement(table_name, job_content, job_content.happening_hours, job_content.happening_pas)
    //   }
    // }), this)
  }
  addTimetableElement(table_name, job_content, hours, pas) {
    if (!this.tables[table_name]) {
      this.tables[table_name] = new TimetableView()
    }

    const unique_id = this.templateID(job_content, hours, pas)

    // Add the element to the timetable.
    this.tables[table_name].addElement(
      job_content.start_time,
      job_content.end_time,
      unique_id
    )
  }
  addListTableElement(table_name, job_content, hours, pas) {
    if (!this.tables[table_name]) {
      let listTableViewOptions = {}
      this.tables[table_name] = new ListTableView({ sortableRows: this.sortableRows })
    }

    const unique_id = this.templateID(job_content, hours, pas)

    const sortable_path = this.prepareSortablePath(job_content)

    // Add the element to the list_table.
    this.tables[table_name].addElement(unique_id, null, sortable_path)
  }

  prepareSortablePath(jc) {
    return (this.sortableRows && this.options.sortablePathTemplate) ? this.options.sortablePathTemplate.replace('JOB_CONTENT_ID', jc.id) : null
  }

  makeUniqueID() {
    if (this.lastID >= 1) {
      this.lastID += 1
    } else {
      this.lastID = 1111111
    }
    return this.lastID
  }

  templateID(job_content, hours, pas) {
    // We use a new unique_id here instead of the job_content.id since there can
    // be multiple elements per job content with different hours and HTML elements
    // need unique ids.
    const unique_id = this.makeUniqueID()

    const humanized_hours = hoursAndMinutes(hours)
    const humanized_hours_long = hoursAndMinutes(hours, { long: true })

    let rounded_pas = null
    if(pas != null) {
      rounded_pas = parseFloat(pas.toFixed(3))
    }

    let overlaps_midnight_class = ''
    if(this.overlapsMidnight(job_content)) {
      overlaps_midnight_class = 'after-midnight'
    }

    // Cache necessary data here so that it can easily be grabbed for rendering by
    // timetableweekoverview_controller.
    this.setTemplateData(unique_id, 'job_content', job_content)
    this.setTemplateData(unique_id, 'hours', humanized_hours)
    this.setTemplateData(unique_id, 'hours_long', humanized_hours_long)
    this.setTemplateData(unique_id, 'impacted_hours', job_content.impacted_hours)
    if(pas != null) {
      this.setTemplateData(unique_id, 'pas', rounded_pas)
      this.setTemplateData(unique_id, 'impacted_pas', job_content.impacted_pas)
    }
    this.setTemplateData(unique_id, 'overlaps_midnight_class', overlaps_midnight_class)

    return unique_id
  }

  setTemplateData(unique_id, key, content) {
    if (!this.template_data[unique_id]) {
      this.template_data[unique_id] = {}
    }
    this.template_data[unique_id][key] = content
  }

  overlapsMidnight(job_content) {
    return job_content.start_time > job_content.end_time
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  forWeekday(job_content) {
    return (job_content.repeats === 'weekly') || (job_content.repeats === 'monthly' && job_content.repeats_monthly === 'on')
  }

  defaultTables() {
    // Set the order that tables are rendered. Feel free to rearrange these as needed.
    return {
      'Monday': new TimetableView(this.minStart, this.maxEnd),
      'Tuesday': new TimetableView(this.minStart, this.maxEnd),
      'Wednesday': new TimetableView(this.minStart, this.maxEnd),
      'Thursday': new TimetableView(this.minStart, this.maxEnd),
      'Friday': new TimetableView(this.minStart, this.maxEnd),
      'Saturday': new TimetableView(this.minStart, this.maxEnd),
      'Sunday': new TimetableView(this.minStart, this.maxEnd),
      'Every n Days': new TimetableView(this.minStart, this.maxEnd),
      'Never Repeats': new TimetableView(this.minStart, this.maxEnd),
      'Other': new TimetableView(this.minStart, this.maxEnd),
      'Flexible': new ListTableView()
    }
  }
  render() {
    const that = this
    // Render timetables and list_tables with ID locations for job_contents.
    return _.reduce(this.tables, (function(html, table, title) {
      if (table.isEmpty()) {
        return html
      } else {
        return html + `
          <div class="jp-overview-container">
            <div class="schedule-heading">
              <div class="day">${title}</div>
            </div>
            <div class="schedule">
              ${table.render()}
            </div>
          </div>
        `
      }
    }), '')
  }
}
