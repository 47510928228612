import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { disableWith: String, originalAnchor: String }

  connect() {
    this.originalAnchorValue = this.element.textContent
    const that = this

    this.element.addEventListener('click', function(event) {
      event.target.classList.add('disabled')

      if(that.disableWithValue) {
        event.target.textContent = that.disableWithValue
      }
    })

    this.element.addEventListener('blur', function(event) {
      event.target.classList.remove('disabled')

      if(that.disableWithValue) {
        event.target.textContent = that.originalAnchorValue
      }
    })
  }
}
